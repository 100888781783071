<template>
	<App title="Transaction Details">
		<div class="row">
			<div class="col">
				<Block type="data">
					<template #header>
						<h3 class="block-title">Transaction Information - #{{ transaction.id }}</h3>
					</template>
					<div class="row">
						<div class="col-8">
							<TextBlock label="User"> {{ transaction.user.fullname }} ({{ transaction.user.email }}) </TextBlock>
						</div>
						<div class="col-4">
							<TextBlock label="Amount">
								{{ $render.money(transaction.amount) }}
							</TextBlock>
						</div>
					</div>
					<div class="row">
						<div class="col-4">
							<TextBlock label="Reference">
								{{ transaction.reference }}
							</TextBlock>
						</div>
						<div class="col-4">
							<TextBlock label="PID">
								{{ transaction.pid }}
							</TextBlock>
						</div>
						<div class="col-4">
							<TextBlock label="Platform">
								{{ transaction.platform }}
							</TextBlock>
						</div>
					</div>
					<div class="row">
						<div class="col-4">
							<TextBlock label="Status">
								{{ transaction.status }}
							</TextBlock>
						</div>
						<div class="col-4">
							<TextBlock label="Type">
								{{ transaction.type }}
							</TextBlock>
						</div>
						<div class="col-4">
							<TextBlock label="Channel">
								{{ transaction.channel }}
							</TextBlock>
						</div>
					</div>
					<div class="row">
						<div class="col-4">
							<TextBlock label="Transactable">
								<a :href="transaction.transactable.url.single"> {{ transaction.transactable?.title ?? transaction.transactable?.id ?? 'N/A' }}</a>
							</TextBlock>
						</div>
						<div class="col-4">
							<TextBlock label="Transactable Type">
								<span class="text-uppercase">{{ transaction.product }}</span>
							</TextBlock>
						</div>
					</div>
					<div class="row">
						<div class="col-4">
							<TextBlock label="Created Date">
								{{ $render.date(transaction.created_at) }}
							</TextBlock>
						</div>
						<div class="col-4">
							<TextBlock label="Verified Date">
								{{ $render.date(transaction.verified_at) }}
							</TextBlock>
						</div>
					</div>

					<template #footer>
						<div class="row">
							<div class="col">
								<button :disabled="!!transaction.verified_at" class="btn btn-outline-warning btn-icon btn-lg me-2" @click="onVerify"><i class="fas fa-check"></i> Verify</button>
							</div>
						</div>
					</template>
				</Block>
			</div>
		</div>
	</App>
</template>
<script lang="ts">
import type Transaction from '@/models/Transaction'
import { error } from '@/ts/app'
import Block from '@/vue/components/Block.vue'
import TextBlock from '@/vue/components/TextBlock.vue'
import Textarea from '@/vue/components/Textarea.vue'
import App from '@/vue/templates/App.vue'
import { AxiosError } from 'axios'
import Swal from 'sweetalert2'
import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

@Component({
	components: {
		App,
		Block,
		TextBlock,
		Textarea,
	},
})
class Single extends Vue {
	@Prop({ type: Object })
	transaction!: Transaction

	loading = false

	@Prop({ type: String })
	readonly id!: string

	onVerify(event: Event) {
		event.preventDefault()
		const button = event.target as HTMLButtonElement
		button.disabled = true
		const label = button.innerHTML
		button.innerHTML = '<i class="fas fa-spinner fa-spin"></i>'

		this.$sweet
			.fire({
				title: 'Are You Sure?',
				text: "You won't be able to revert this!",
				icon: 'warning',

				showCancelButton: true,
				confirmButtonText: 'YES',
			})
			.then((result) => {
				if (result.isConfirmed) {
					this.$http
						.patch(this.route('transaction.ajax.verify', { transaction: this.transaction.id }))
						.then(() => ((button.innerHTML = 'Done'), this.router.reload({ only: ['transaction'] })))
						.catch((e: AxiosError) => {
							error(e)
							button.innerHTML = label
							// @ts-ignore
							Swal.fire(e.response?.data?.message)
						})
						.finally(() => (button.disabled = false))
				} else {
					button.disabled = false
					button.innerHTML = label
				}
			})
	}
}
export default toNative(Single)
</script>

<style lang="scss">
.content p,
.content .push,
.content .block,
.content .items-push > div {
	margin-bottom: 10px;
}
</style>
